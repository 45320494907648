import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, HashNavigation, Navigation } from "swiper";
const imgData = [
  {
    img: "https://res.cloudinary.com/divxqgoph/image/upload/v1685926613/mehendibymimi/slider/UmrrdrXPRdBRw1ak9dTYsOteU9NfLMMRzr72h5wo_cygobd.jpg",
  },
  {
    img: "https://res.cloudinary.com/divxqgoph/image/upload/v1685926612/mehendibymimi/slider/nhwpINEZyKtB2nuG6ny40H6UlSOa9OMzNPE68BnC_nre3dt.jpg",
  },
  {
    img: "https://res.cloudinary.com/divxqgoph/image/upload/v1685926612/mehendibymimi/slider/ojBqUVWfVPDjFP6vWxNGtl2iPLetvlh51HXZSLE2_uk3dhw.jpg",
  },
];
const imgData1 = [
  {
    img: "https://res.cloudinary.com/divxqgoph/image/upload/v1685926750/mehendibymimi/review%20slider/Hand_Mehendi-product_img_cop3ln.jpg",
  },
  {
    img: "https://res.cloudinary.com/divxqgoph/image/upload/v1685926731/mehendibymimi/review%20slider/Mehendi_pigeon-product_img_vtgvvi.jpg",
  },
  {
    img: "https://res.cloudinary.com/divxqgoph/image/upload/v1685926715/mehendibymimi/review%20slider/Mehendi_Review-product_img_z39shi.jpg",
  },
];
const BannerSlider = () => {
  return (
    <div className="container mx-auto  mt-5 ">
      <div className="grid md:grid-cols-4 grid-cols-1 gap-3">
        <div className="banner-slider-frame col-span-3 ">
          <div className="banner-slider  ">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              speed={700}
              hashNavigation
              loop={true}
              modules={[Autoplay, Navigation, HashNavigation]}
              className="mySwiper h-full"
            >
              {imgData.map((image) => (
                <div>
                  <SwiperSlide>
                    <div className="w-full  md:h-[21rem] h-[12rem] 2xl:h-[30rem] overflow-hidden">
                      <img
                        key={image.id}
                        src={image.img}
                        alt={image.alt}
                        className={` h-full  w-full  transition-opacity duration-500 object-fill `}
                      />
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
        <div>
          <div className="h-full hidden md:block">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              speed={700}
              loop={true}
              hashNavigation
              modules={[Autoplay, Navigation, HashNavigation]}
              className="mySwiper h-full"
            >
              {imgData1.map((image) => (
                <div>
                  <SwiperSlide>
                    <div className="w-full  h-[21rem]  2xl:h-[30rem] overflow-hidden">
                      <img
                        key={image.id}
                        src={image.img}
                        alt={image.alt}
                        className={` h-full w-full  transition-opacity duration-500 object-cover 2xl:object-fill`}
                      />
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
