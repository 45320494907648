import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  const { items: data } = useSelector((state) => state.category);
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#D89307" : null,
    };
  };
  return (
    <div className="bg-[#331b08] text-slate-50 md:px-10 md:py-8 p-4">
      <div className="container mx-auto gap-5 flex flex-col lg:flex-row justify-evenly ">
        <div className=" sm:w-[10rem] md:w-96 ">
          <span className="font-light text-sm">
            Mehendi By Mimi establish in 2019 march.We provide all mehendi art
            design service & Our Own brand Product Organic mehendi selling.
            <br />
            Our Trade licence :DNCC/117118/2022. BIN:003271717-0101. E-commerce
            Association Of Bangladesh Membership Id: 1978.
            <br />
            We also have I.R.C licence.
          </span>
          <img src="logo.png" className="w-[14rem]" alt="" />
          <h2 className="text-md mb-4 font-light">
            Subscribe to Our Newsletter
          </h2>
          <form>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mb-4">
              <label className="sr-only" htmlFor="email">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                className="bg-slate-50 rounded-lg py-2 px-4 text-white w-[15rem]"
              />
              <button
                type="submit"
                className="bg-black rounded-lg py-2 px-4 ml-2"
              >
                Subscribe
              </button>
            </div>
          </form>
          <div className="flex  items-center gap-4">
            <h2 className="text-md font-light">Follow Us</h2>
            <div className="flex items-center">
              <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400 mr-4"
              >
                <FaFacebook />
              </Link>
              <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:hover:text-blue-400 mr-4"
              >
                <FaTwitter />
              </Link>
              <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-red-400"
              >
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>
        <div className=" flex flex-col sm:flex-row items-start mt-4 md:mt-0 gap-10">
          <div className="flex flex-col ">
            <h2 className="text-md mb-2 text-[#e7e4e2] ">Useful Links</h2>
            <NavLink
              style={navActive}
              to={"/"}
              end
              className=" inline-block text-sm font-light text-slate-50 "
            >
              Home
            </NavLink>
            {data?.slice(0, 5).map((navItem) => (
              <NavLink
                style={navActive}
                to={`/${navItem.slug}/${navItem.id}`}
                key={navItem.id}
                className="text-slate-50 text-sm  font-light"
              >
                {navItem.name}
              </NavLink>
            ))}
            <Link
              to="terms"
              className=" inline-block text-sm font-light text-slate-50 "
            >
              Terms & conditions
            </Link>

            <Link
              to="returnpolicy"
              className=" inline-block text-sm font-light text-slate-50"
            >
              Return policy
            </Link>
            <Link
              to="supportpolicy"
              className=" inline-block text-sm font-light text-slat NavLink
            "
            >
              Support Policy
            </Link>
            <Link
              className=" inline-block text-sm font-light text-slat NavLink
            "
              to="privacypolicy"
            >
              Privacy policy
            </Link>
          </div>
          <div className="flex flex-col ">
            <h2 className="text-md mb-2 text-[#e7e4e2]  ">Company</h2>
            <Link
              to={"/about"}
              className=" inline-block text-sm font-light  text-slate-50"
            >
              About us
            </Link>
            <Link className="inline-block text-sm font-light text-slate-50 ">
              Contact us
            </Link>
            <Link
              to={"/review"}
              className=" inline-block text-sm font-light text-slate-50"
            >
              Review
            </Link>
            <Link
              to={"/sellcondition"}
              className=" inline-block text-sm font-light text-slate-50"
            >
              ক্রয় বিক্রয় ও অন্যান্য শর্তাবলী
            </Link>
          </div>
          <div className="flex flex-col ">
            <h2 className="text-md mb-2 text-[#e7e4e2] ">Contact info</h2>
            <Link className=" inline-block text-sm font-light text-slate-50 ">
              ক-১/১ সি,কালাচাঁদপুর,গুলশান,ঢাকা-১২১২
            </Link>

            <Link className=" inline-block text-sm font-light text-slate-50">
              Phone: +8809696300486
            </Link>
            <Link
              className=" inline-block text-sm font-light text-slat NavLink
            "
            >
              Email: mehendibymimi@gmail.com
            </Link>
          </div>
        </div>
      </div>
      <footer className="footer flex items-center justify-between px-0 md:px-20   gap-5  text-slate-50 ">
        <div className="flex justify-center">
          <p className="text-slate-50 text-sm font-light mt-5">
            &copy; 2022 MehendibyMimi. All rights reserved.
          </p>
        </div>
        <div className="image-container w-60 ">
          <img
            src="https://mehendibymimi.com/public/uploads/all/lnfIO3PdB1OE45OgRLmHu8fZdLRselIYRdKpAyam.png"
            alt=""
          />
        </div>
      </footer>
    </div>
  );
};

export default Footer;
