import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSubtotal,
  removeAllFromCart,
  // removeFromCart,
} from "../features/products/cartSlice";

import { currencyFormatter } from "../utils/currencyFormatter";
import { useNavigate } from "react-router-dom";
// import { RiDeleteBin6Line } from "react-icons/ri";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const [orderItem, setOrderItem] = useState(data);

  console.log(JSON.stringify(orderItem).split(" "));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: "",
    shipping_type: "",
  });
  console.log(formData.shipping_type);
  let grand_total = cartTotalAmount + +formData.shipping_cost;
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log(JSON.parse(JSON.stringify(orderItem)));
    //post req here
    // try {
    //   const res = await fetch(`${process.env.REACT_APP_ORDER_URL}`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       name: formData.name,
    //       email: formData.email,
    //       phone: formData.phone,
    //       address: formData.address,
    //       item: orderItem,
    //       shipping_cost: formData.shipping_cost,
    //       shipping_type: formData.shipping_type,
    //       grand_total,
    //     }),
    //   });

    //   if (!res.ok) throw new Error("Something Went Error");
    //   if (res.ok) {
    //     dispatch(removeAllFromCart());
    //     navigate("/login");
    //   }
    // } catch (err) {
    //   console.log(err.message);
    // }

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        const res = await fetch(`${process.env.REACT_APP_ORDER_URL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: orderItem,
            shipping_cost: formData.shipping_cost,
            shipping_type: formData.shipping_type,
            grand_total,
          }),
        });

        if (!res.ok) throw new Error("Something Went Error");
        if (res.ok) {
          dispatch(removeAllFromCart());
          navigate("/login");
        }
      } catch (err) {
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "SSL Commerz") {
      // try {
      //   const res = await fetch(
      //     `${process.env.REACT_APP_ORDER_URL}`,
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify({
      //         name: formData.name,
      //         email: formData.email,
      //         phone: formData.phone,
      //         address: formData.address,
      //         item: orderItem,
      //         shipping_cost: formData.shipping_cost,
      //         shipping_type: formData.shipping_type,
      //         grand_total,
      //       }),
      //     }
      //   );

      //   if (!res.ok) throw new Error("Something Went Error");
      //   if (res.ok) {
      //     dispatch(removeAllFromCart());
      //     navigate("/login");
      //   }
      // } catch (err) {
      //   console.log(err.message);
      // }
      const queryParams = new URLSearchParams({
        name: formData.name,
        item: JSON.stringify(orderItem),
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        grand_total,
        shipping_type: formData.shipping_type,
        shipping_cost: formData.shipping_cost,
      });
      // queryParams.append("name", formData.name);
      // queryParams.append("email", formData.email);
      // queryParams.append("phone", formData.phone);
      // queryParams.append("address", formData.address);
      // queryParams.append("grand_total", grand_total);
      // queryParams.append("shipping_type", formData.shipping_type);
      // queryParams.append("shipping_cost", formData.shipping_cost);
      // queryParams.append("item", JSON.stringify(orderItem));

      console.log(formData.shipping_cost);
      // const data = orderItem.map(
      //   (item) => (
      //     queryParams.append("product_name", item.name),
      //     queryParams.append("quantity", item.cartQuantity),
      //     queryParams.append("product_id", item.id),
      //     queryParams.append("price", item.unit_price)
      //   )
      // );
      // queryParams.append("product_name", data);
      // queryParams.append("product_id", data);

      // this.props.history.push(
      //   "https://www.test.mehendibymimi.com/admin/public/example2/?" +
      //     queryParams.toString()
      // );
      window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/?${queryParams}`;
    }
  };
  // const handleRemove = (product) => {
  //   dispatch(removeFromCart(product));
  // };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="checkout-page px-2 mt-10 mb-10 container min-h-screen mx-auto grid grid-cols-1 md:grid-cols-2 ">
        <div className="billing-address flex flex-col order-2 md:order-1">
          <h2 className="text-2xl mt-5 md:mt-0 mb-5">Billing Address</h2>
          <div className="form-control flex flex-col gap-2  mb-3">
            <label className="mb-2">Full Name</label>
            <input
              required
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              value={formData.name}
              className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
              placeholder="Enter Your First Name"
            />
          </div>
          <div className="form-control flex flex-col mb-3">
            <label className="mb-2">Phone Number</label>
            <input
              required
              type="tel"
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              value={formData.phone}
              className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300"
              placeholder="Enter Your Number"
            />
          </div>
          <div className="form-control flex flex-col mb-3">
            <label className="mb-2">Email Address</label>
            <input
              className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              value={formData.email}
              type="email"
              placeholder="Enter Your Email..."
            />
          </div>

          <div className="form-control flex flex-col  mb-3 ">
            <label className="mb-2">Address</label>
            <textarea
              required
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              value={formData.address}
              className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
              placeholder="Enter Your Address"
            />
          </div>
          {/* <div className="form-control flex flex-col  mb-3 ">
            <label className="mb-2">Shipping Cost</label>
            <textarea
              required
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, shipping_cost: e.target.value })
              }
              value={formData.shipping_cost}
              className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
              placeholder="Enter Your shipping cost"
            />
          </div> */}
          <div className="form-control flex flex-col gap-2  mb-3">
            <select
              onChange={(e) =>
                setFormData({ ...formData, shipping_cost: e.target.value })
              }
              className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
            >
              <option>Select Area</option>
              <option value="0">Inside Dhaka(0)</option>
              <option value="0">OutSide Dhaka(0)</option>
              <option value="0">Sub City(0)</option>
            </select>
          </div>

          <div className="form-control flex items-end justify-between  ">
            <div className="flex">
              <button
                type="submit"
                className="bg-[#b88313] px-5 py-3 rounded-md"
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
        <div className="order-details md:ml-40 ml-0 md:order-2 order-1">
          <h2 className="text-2xl">Order Details</h2>

          <div className="h-auto overflow-y-scroll order-details-container">
            {data?.map((product) => (
              <div className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden">
                <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                  <img
                    src={`${process.env.REACT_APP_PRODUCTS_IMAGE_URL}/${product?.photos}`}
                    className="w-full object-cover "
                    alt=""
                  />
                </div>
                <div className="cart-description w-full overflow-hidden ">
                  <div className="flex flex-col">
                    <p className="truncate">
                      {product?.name.substring(0, 60)}...
                    </p>
                    <div className="cart-price">
                      {currencyFormatter(
                        product?.unit_price * product?.cartQuantity
                      )}
                    </div>
                    {/* <button
                      className="text-base hover:text-red-500 duration-500 justify-center"
                      onClick={() => handleRemove(product)}
                    >
                      <RiDeleteBin6Line />
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 ">
            {/* <div className="flex items-center gap-3">
              <input
                required
                onChange={(e) =>
                  setFormData({ ...formData, shipping_type: e.target.value })
                }
                type="checkbox"
                value="Cash On Delivery"
                name=""
                id=""
              />
              <span className="text-[0.9rem] text-[#212121]">
                Cash On Delivery
              </span>
            </div> */}
            <div>
              <label className="cursor-pointer">
                <input
                  type="radio"
                  onChange={(e) =>
                    setFormData({ ...formData, shipping_type: e.target.value })
                  }
                  name="shipping_type"
                  value={"Cash On Delivery"}
                  className="peer sr-only"
                />
                <div className="w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                  <img
                    src="./cash_on_delivery.jpg"
                    alt=""
                    className="w-full h-12 "
                  />
                </div>
              </label>
            </div>
            <div>
              <label className="cursor-pointer relative">
                <input
                  type="radio"
                  onChange={(e) =>
                    setFormData({ ...formData, shipping_type: e.target.value })
                  }
                  className="peer sr-only"
                  name="shipping_type"
                  value={"SSL Commerz"}
                />
                <div className=" w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                  {/* <p class="text-sm font-semibold uppercase text-gray-500">
                        Cash On Delivery
                      </p> */}
                  <img src="./ssl.jpg" alt="" className="w-full h-12 " />
                </div>
              </label>
            </div>
          </div>
          <div className="payment-summary flex flex-col gap-3 mt-5 ">
            <div className="sub-total flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold">
                Sub Total
              </h4>
              <span className="text-slate-700 ">
                {currencyFormatter(cartTotalAmount)}
              </span>
            </div>
            <div className="shipping flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold ">
                Shipping
              </h4>
              <span className="text-slate-700 ">
                {currencyFormatter(+formData.shipping_cost)}
              </span>
            </div>
            <div className="sub-total flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold">Total</h4>
              <span className="text-slate-700 font-semibold ">
                {currencyFormatter(grand_total)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Checkout;
