const Banner = () => {
  return (
    <div className="container mx-auto my-10">
      <div className="grid md:grid-cols-2  gap-5">
        <div className="banner-image rounded-md overflow-hidden ">
          <img
            src="https://res.cloudinary.com/divxqgoph/image/upload/v1685926612/mehendibymimi/slider/ojBqUVWfVPDjFP6vWxNGtl2iPLetvlh51HXZSLE2_uk3dhw.jpg"
            alt=""
          />
        </div>
        <div className="banner-image rounded-md overflow-hidden">
          <img
            src="https://res.cloudinary.com/divxqgoph/image/upload/v1685926612/mehendibymimi/slider/nhwpINEZyKtB2nuG6ny40H6UlSOa9OMzNPE68BnC_nre3dt.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
