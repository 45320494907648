import React from "react";

const BannerToow = () => {
  return (
    <div className="container mx-auto my-10">
      <div className="banner-image rounded-md overflow-hidden ">
        <img
          src="https://mehendibymimi.com/public/uploads/all/iPMAo2463f8L8ZkRPldIqfSwnBqqgEQSL3jLdbdm.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default BannerToow;
