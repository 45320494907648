import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProductImageCarasol = ({ singleProduct }) => {
  return (
    <div className="text-white text-[20px] w-auto md:w-[30rem]  lg:w-[40rem] object-cover">
      <Carousel
        showIndicators={false}
        showStatus={false}
        thumbWidth={60}
        infiniteLoop={true}
        className="productCarousel"
      >
        <img
          src={singleProduct}
          alt=""
          className="w-auto h-[30rem] object-cover "
        />
      </Carousel>
    </div>
  );
};

export default ProductImageCarasol;
